import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { UserAdminResponse, UserConfig } from './model/user-admin.model';
import { AuthService } from '../common/auth.service';

import { ConfigService } from '../common/config.service';

@Injectable({
  providedIn: 'root'
})
export class UserAdminService {

  constructor(private http: HttpClient, private authService: AuthService, private configService: ConfigService) {
  }

  getUserConfigs(): Observable<UserAdminResponse> {
      return this.http.post<UserAdminResponse>(this.configService.config.apiUrl + '/user-config/configs',
                                               {token : this.authService.token});
  }

  saveConfig(config: UserConfig): Observable<UserAdminResponse> {
    return this.http.post<UserAdminResponse>(this.configService.config.apiUrl + '/user-config/configs/update',
                                             {userConfig: config, token : this.authService.token});
 }

 deleteConfig(config: UserConfig): Observable<UserAdminResponse> {
  return this.http.post<UserAdminResponse>(this.configService.config.apiUrl + '/user-config/configs/delete',
                                           {userConfig: config, token : this.authService.token});
}

 getTrademarkFields(): Observable<UserAdminResponse> {
  return this.http.post<UserAdminResponse>(this.configService.config.apiUrl + '/user-config/trademark-fields',
                                           {token : this.authService.token});
}

getUserStats(customerId: string): Observable<UserAdminResponse> {
  return this.http.post<UserAdminResponse>(this.configService.config.apiUrl + '/user-config/user-stats',
                                           {customerId: customerId, token : this.authService.token});
}

}
