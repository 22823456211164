import { Component, OnInit, Input } from '@angular/core';
import { UserStats } from '../model/user-admin.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-redis-info-modal',
  templateUrl: './redis-info-modal.component.html',
  styleUrls: ['./redis-info-modal.component.css']
})
export class RedisInfoModalComponent implements OnInit {

  @Input() stats: UserStats;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  getStatsDatabases(): string[] {
    const result = Object.keys(this.stats.statsPerDatabase);
    result.sort();
    return result;
  }
}
