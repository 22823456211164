import { Component, OnInit, Input } from '@angular/core';
import { UserConfig } from '../model/user-admin.model';

@Component({
  selector: 'app-database-cutoffs',
  templateUrl: './database-cutoffs.component.html',
  styleUrls: ['./database-cutoffs.component.css']
})
export class DatabaseCutoffsComponent implements OnInit {

  @Input()
  public config: UserConfig;

  constructor() { }

  ngOnInit() {
  }

  fileChanged (event: any): void {
    const file: File = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.onloadend = (e) => {
      this.parseFile(fileReader.result);
    };
    fileReader.readAsText(file);
    event.target.value = null;
  }

  private parseFile (fileContent: any): void {
    const lines: string[] = fileContent.split('\n');

    const capPerRegister = {};
    const capRegisterWarning = {};

    for (const line of lines) {
        if(line.trim().length==0){
          break;
        }
        const lineParts = line.split(',');
        const db = lineParts[0].trim().toUpperCase();
        const cap = lineParts[1].trim();
        const warning = lineParts[2].trim();
        if (lineParts.length !== 3 || (cap !== '' && !this.isNumber(cap)) || (warning!=='' && !this.isNumber(warning))) {
          alert('Invalid cutoffs per database file !!!');
          return;
        }
        capPerRegister[db] =  cap==''?null:Number(cap);
        capRegisterWarning[db] = warning==''?null:Number(warning);
    }

    this.config.usageCapPerRegister = capPerRegister;
    this.config.usageCapRegisterWarning = capRegisterWarning;

  }

  private isNumber(value: string | number): boolean {
    return !isNaN(Number(value.toString()));
 }

  getUploadedDatabases(): string[] {
    return Object.keys(this.config.usageCapPerRegister);
  }

}
