import { Component, OnInit } from '@angular/core';

import { UserAdminService } from '../user-admin.service';
import { UserConfig, TrademarkField, UserStats } from '../model/user-admin.model';

import { NgbDateParserFormatter, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from '../../common/auth.service';
import { RedisInfoModalComponent } from '../redis-info-modal/redis-info-modal.component';

@Component({
  selector: 'app-user-admin',
  templateUrl: './user-admin.component.html',
  styleUrls: ['./user-admin.component.css']
})

export class UserAdminComponent implements OnInit {

  public configs: UserConfig[];
  public stats: UserStats;
  public selectedConfig: UserConfig;
  public trademarkFieldRoot: TrademarkField;
  public saveSuccess: boolean;
  public createMode: boolean;
  public errorMessage: string;

  constructor(private adminService: UserAdminService, private dataParser: NgbDateParserFormatter, private authService: AuthService, private modalService: NgbModal) { }

  ngOnInit() {
      this.adminService.getTrademarkFields().subscribe(data => {
           this.trademarkFieldRoot = data.trademarkFieldTree;
           this.reloadConfigs();
      }, error => {
        alert(error);
      });
  }

  closeSuccessAlert(): void {
    this.saveSuccess = false;
  }

  closeErrorAlert(): void {
    this.errorMessage = undefined;
  }

  private reloadConfigs(): void {
    this.adminService.getUserConfigs().subscribe(data => {
      data.configs.sort((n1, n2) => {
        if (n1.customerId > n2.customerId) {
            return 1;
        }
        if (n1.customerId < n2.customerId) {
            return -1;
        }
        return 0;
      });
      this.configs = data.configs;
    }, error => {
      alert(error.message);
    });
  }

  createConfig(): void {
    this.saveSuccess = false;
    this.createMode = true;
    this.selectedConfig = {
        customerId: null,
        memberId: null,
        loginGuid: null,
        updatedDt: null,
        updatedBy: null,
        testMode: false,
        invoicing: true,
        allMethodsAllowed: false,
        allowedMethods: [],
        allDatabasesAllowed: false,
        allowedDatabases: [],
        allowedDatabasesString: '',
        allFieldsAllowed: false,
        allowedFields: [],
        contractDt: null,
        totalCap: 0,
        totalCapCutOff: 0,
        totalCapWarning: 0,
        usageCapPerRegister: {},
        usageCapRegisterWarning: {},
        recieveUpdates : false,
        recieveAllUpdates : false,
        sendErrorEmails : true,
        updateUrl:{
          url: null,
          password: null,
          user: null,
          securityToken: null,
          securityTokenHeader: null,
          securityMethod: 'API_KEY',
          servicePath: null,
          key: null,
          iss: null,
          sub: null,
          aud: null
        }
    };
  }

  saveConfig(): void {
    this.saveSuccess = false;
    this.errorMessage = undefined;
    this.preprocessSelectedConfig();
    this.selectedConfig.updatedBy = this.authService.currentUser;
    this.adminService.saveConfig(this.selectedConfig).subscribe(data => {
      if (data.status === 'OK') {
        this.saveSuccess = true;
        if (this.createMode) {
          this.reloadConfigs();
        }
      }else{
        this.errorMessage = data.validationMessage;
      }
    }, error => {
      alert(error.message);
    });
  }

  private preprocessSelectedConfig() {
    if(this.selectedConfig.allowedDatabasesString.length==0) {
      this.selectedConfig.allowedDatabases = [];
    } else {
      this.selectedConfig.allowedDatabases = this.selectedConfig.allowedDatabasesString.split(',')
                                                                                     .map(s => s.toUpperCase().trim());
    }
    this.selectedConfig.contractDt = this.dataParser.format(this.selectedConfig.contractDtModel);
    this.selectedConfig.updatedBy = this.authService.currentUser;
  }

  deleteConfig(): void {
    this.saveSuccess = false;
    this.errorMessage = undefined;
    this.adminService.deleteConfig(this.selectedConfig).subscribe(data => {
          this.reloadConfigs();
          this.selectedConfig = null;
    }, error => {
      alert(error.message);
    });
  }

  showRedisInfo(content:any): void {
    this.adminService.getUserStats(this.selectedConfig.customerId).subscribe(data => {
          this.stats = data.stats;
          const modalRef = this.modalService.open(RedisInfoModalComponent);
          modalRef.componentInstance.stats = this.stats;
    }, error => {
      alert(error.message);
    });

  }

  selectedRow(config: UserConfig): void {
    this.saveSuccess = false;
    this.errorMessage = undefined;
    this.createMode = false;
    this.selectedConfig = config;
    this.selectedConfig.allowedDatabasesString = this.selectedConfig.allowedDatabases.join(',');
    this.selectedConfig.contractDtModel = this.dataParser.parse(this.selectedConfig.contractDt);
  }


}
