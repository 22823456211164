import { Component, OnInit, Input, AfterViewInit, AfterViewChecked, OnChanges } from '@angular/core';
import { TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { TrademarkField, UserConfig } from '../model/user-admin.model';

@Component({
  selector: 'app-allowed-fields',
  templateUrl: './allowed-fields.component.html',
  styleUrls: ['./allowed-fields.component.css']
})
export class AllowedFieldsComponent implements OnInit, OnChanges, AfterViewInit {

  @Input()
  public root: TrademarkField;
  @Input()
  public config: UserConfig;

  public treeViewItems: TreeviewItem[];

  constructor(private treeViewConfig: TreeviewConfig) { }

  ngOnInit() {
    this.treeViewConfig.hasFilter = true;
    this.treeViewConfig.maxHeight = 300;
    this.treeViewConfig.hasCollapseExpand = true;
  }

  ngAfterViewInit() {
    this.populateTreeView();
  }

  ngOnChanges() {
    this.populateTreeView();
  }

  private populateTreeView() {
    const treeViewRoot: TreeviewItem = new TreeviewItem({ text: 'Trademark', value: 'ROOT' });
    this.populateTreeViewItems(this.config, this.root, treeViewRoot);
    treeViewRoot.correctChecked();
    this.treeViewItems = treeViewRoot.children;
  }

  populateTreeViewItems(config: UserConfig, trademarkField: TrademarkField, treeViewItem: TreeviewItem): void {
    for (const child of trademarkField.childFields) {
      const item = new TreeviewItem({ text: child.name,
                                      value: child.path,
                                      checked: config.allowedFields.indexOf(child.path) !== -1});
      if (!treeViewItem.children) {
        treeViewItem.children = [ item ];
      } else  {
        treeViewItem.children.push(item);
      }
      this.populateTreeViewItems(config, child, item);
    }
 }

  onSelectedChange(event: string[]): void {
    this.config.allowedFields = event;
  }
}
