import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserAdminComponent } from './user-admin/user-admin.component';

import {NgbModule, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { NgbDateCustomParserFormatter } from './user-admin/NgbDateCustomParserFormatter';

import { TreeviewModule, TreeviewConfig, TreeviewI18n, TreeviewI18nDefault,
         DefaultTreeviewEventParser, TreeviewEventParser } from 'ngx-treeview';
import { CoreModule } from '../common/core.module';
import { AllowedMethodsComponent } from './allowed-methods/allowed-methods.component';
import { AllowedFieldsComponent } from './allowed-fields/allowed-fields.component';
import { DatabaseCutoffsComponent } from './database-cutoffs/database-cutoffs.component';
import { AllowedDatabasesComponent } from './allowed-databases/allowed-databases.component';
import { RedisInfoModalComponent } from './redis-info-modal/redis-info-modal.component';
import { ContentUpdatesConfigComponent} from './content-updates-config/content-updates-config.component'


@NgModule({
  declarations: [
    ContentUpdatesConfigComponent,
    UserAdminComponent,
    AllowedMethodsComponent,
    AllowedFieldsComponent,
    DatabaseCutoffsComponent,
    AllowedDatabasesComponent,
    RedisInfoModalComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    TreeviewModule,
    CoreModule
  ],
  exports: [UserAdminComponent],
  providers: [
    TreeviewConfig,
    { provide: TreeviewI18n, useClass: TreeviewI18nDefault },
    { provide: TreeviewEventParser, useClass: DefaultTreeviewEventParser },
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter}
   ],
   entryComponents: [
    RedisInfoModalComponent
  ]
})
export class UsersModule { }
