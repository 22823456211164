import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export interface UserStats {
    customerId: string;
    total: number;
    statsPerDatabase: Object;
}

export interface UserConfig {
    customerId: string;
    memberId: number;
    loginGuid: string;
    updatedDt: string;
    updatedBy: string;
    testMode: boolean;
    invoicing: boolean;
    allMethodsAllowed: boolean;
    allowedMethods: string[];
    allDatabasesAllowed: boolean;
    allowedDatabases: string[];
    allowedDatabasesString: string;
    allFieldsAllowed: boolean;
    allowedFields: string[];
    contractDt: string;
    contractDtModel?: NgbDateStruct;
    totalCap: number;
    totalCapCutOff: number;
    totalCapWarning: number;
    usageCapPerRegister: Object;
    usageCapRegisterWarning: Object;
    recieveUpdates: boolean;
    updateUrl: UpdateUrl;
    recieveAllUpdates: boolean;
    sendErrorEmails: boolean;
}

export interface UserAdminResponse {
    configs: UserConfig[];
    userConfig: UserConfig;
    trademarkFieldTree: TrademarkField;
    status: string;
    token: string;
    validationMessage: string;
    stats: UserStats;
}

export interface ListItem {
    id: string;
    label: string;
    order?: number;
}

export const API_METHODS: ListItem[] = [
    { order: 1, id: 'CONTENTAPI.TRM-TEXT', label: 'Content API - Trademark Text' },
    { order: 2, id: 'CONTENTAPI.FD', label: 'Content API - Filing Dates' },
    { order: 3, id: 'CONTENTAPI.IMG', label: 'Content API - Image' },
    { order: 4, id: 'CONTENTAPI.TRM-SEARCH', label: 'Content API - Trademark Search' },
    { order: 5, id: 'CONTENTAPI.TRM-COUNT', label: 'Content API - Trademark Count' },
    { order: 6, id: 'CONTENTAPI.COMP-RES', label: 'Content API - Company Resolution' },
    { order: 7, id: 'CONTENTAPI.TRM-RES', label: 'Content API - Trademark Resolution' },
    { order: 8, id: 'CONTENTAPI.DOM-TEXT', label: 'Content API - Domain Name Text' },
    { order: 9, id: 'CONTENTAPI.DOM-SEARCH', label: 'Content API - Domain Name Search' },
    { order: 10, id: 'CONTENTAPI.DOM-COUNT', label: 'Content API - Domain Name Count' },
    { order: 11, id: 'CONTENTAPI.SIM-TRM-SEARCH', label: 'Content API - Similarity Search' },
    { order: 13, id: 'CONTENTAPI.IMG-SEARCH', label: 'Content API - Image Search' },
    { order: 15, id: 'DOCAPI.SEARCH-ORDERS', label: 'Document API - List Search Orders' },
    { order: 16, id: 'DOCAPI.SEARCH-DOCS', label: 'Document API - List Search Documents' },
    { order: 17, id: 'DOCAPI.WATCH-ORDERS', label: 'Document API - List Watch Orders' },
    { order: 18, id: 'DOCAPI.WATCH-DOCS', label: 'Document API - List Watch Documents' },
    { order: 19, id: 'DOCAPI.RETRIEVE-DOC', label: 'Document API - Retrieve Document' },
    { order: 20, id: 'DOCAPI.RETRIEVE-ORDER-IMG', label: 'Document API - Retrieve Order Image' },
    { order: 21, id: 'DOCAPI.WATCH-JSON-DOCS', label: 'Document API - List Watch Json Documents' },
    { order: 22, id: 'DOCAPI.RETRIEVE-JSON', label: 'Document API - Retrieve Json Document' },
    { order: 23, id: 'DOCAPI.RETRIEVE-JSON-IMG', label: 'Document API - Retrieve Json Image' },
    { order: 24, id: 'DOCAPI.RETRIEVE-ANNO', label: 'Document API - Retrieve Annotations' },
    { order: 25, id: 'DOCAPI.SAVE_PROJECT', label: 'Document API - Save Project' },
    { order: 26, id: 'DOCAPI.ADD_PROJECT_IMAGE', label: 'Document API - Add Project Image' },
    { order: 27, id: 'DOCAPI.DEL_PROJECT_IMAGE', label: 'Document API - Delete Project Image' },
    { order: 28, id: 'DOCAPI.SERION_ENTRY_POINTS', label: 'Document API - Retrieve Serion Entry Points' }
];

export interface TrademarkField {
    name: string;
    path: string;
    order: number;
    childFields: TrademarkField[];
}

export interface UpdateUrl{
    url: string;
    securityMethod: string;
    securityToken: string;
    securityTokenHeader: string;
    user: string;
    password: string;
    servicePath: string;
    key: string;
    iss: string;
    sub: string;
    aud: string;
}
