import { Component, OnInit, Input } from '@angular/core';
import { ListItem, UserConfig, API_METHODS } from '../model/user-admin.model';

@Component({
  selector: 'app-content-updates-config',
  templateUrl: './content-updates-config.component.html',
  styleUrls: ['./content-updates-config.component.css']
})
export class ContentUpdatesConfigComponent implements OnInit {

  @Input() public config: UserConfig;

  constructor() { }

  onSecurityMethodChange(value: string){
    this.config.updateUrl.securityMethod = value;
  }

  ngOnInit() {
  }

}
