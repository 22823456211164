import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { AdminResponse } from './model/common-admin.model';

import { ConfigService } from '../common/config.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public message: string;
  public token: string;
  public currentUser: string;

  constructor(private http: HttpClient, private configService: ConfigService) { }

  login(name: string, password: string): void {
     this.http.post<AdminResponse>(this.configService.config.apiUrl + '/user-config/auth',
                                             {loginName: name, loginPassword: password}).subscribe(data => {
                                              if (data.status === 'ACCESS_DENIED') {
                                                this.message = 'Access Denied !!!';
                                              } else {
                                                this.token = data.token;
                                                this.currentUser = data.currentUser;
                                              }
                                            }, error => {
                                              alert(error.message);
                                            });
 }

 isAuthenticated(): boolean {
  return this.token !== undefined;
}


}
