import { Component, OnInit, Input } from '@angular/core';
import { UserConfig } from '../model/user-admin.model';

@Component({
  selector: 'app-allowed-databases',
  templateUrl: './allowed-databases.component.html',
  styleUrls: ['./allowed-databases.component.css']
})
export class AllowedDatabasesComponent implements OnInit {

  @Input() config: UserConfig;

  constructor() { }

  ngOnInit() {
  }

}
