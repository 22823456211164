import { Component, OnInit, Input } from '@angular/core';
import { ListItem, UserConfig, API_METHODS } from '../model/user-admin.model';

@Component({
  selector: 'app-allowed-methods',
  templateUrl: './allowed-methods.component.html',
  styleUrls: ['./allowed-methods.component.css']
})
export class AllowedMethodsComponent implements OnInit {

  public methods = API_METHODS;
  @Input() public config: UserConfig;

  constructor() { }

  ngOnInit() {
  }

  updateAllowedMethods(method: ListItem, event: any): void {
    if (event.currentTarget.checked) {
        this.config.allowedMethods.push(method.id);
    } else {
        const index = this.config.allowedMethods.indexOf(method.id);
        this.config.allowedMethods.splice(index, 1);
    }
  }
}
