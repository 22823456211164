import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuration } from './model/common-admin.model';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  public config: Configuration;

  constructor(private http: HttpClient) { 

      this.http.get<Configuration>("assets/config/config.json").subscribe(data =>{
        this.config = data;
      });

  }

}
