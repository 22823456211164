import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private authService: AuthService) { }

  ngOnInit() {
  }


  isAuthenticated(): boolean {
    return this.authService.isAuthenticated();
  }

  getAuthMessage(): string {
    return this.authService.message;
  }

  login(loginForm: any): void {
      this.authService.login(loginForm.loginName, loginForm.password);
  }

  closeAuthAlert(): void {
    this.authService.message =  undefined;
  }

  keyDownFunction(event: any, loginForm: any): void {
    if (event.keyCode === 13) {
      this.login(loginForm);
    }
  }

}
